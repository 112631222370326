<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" md="8" class="pa-0">
      <v-card
        class="mx-xs-0 mx-sm-auto px-xs-0 px-md-4 py-4"
        max-width="100%"
        outlined
      >
        <CardToolbar
          :disableAdd="loading"
          :disableRefresh="loading"
          :icon="CLIENTS_ICON"
          @add-data="openDialog()"
          @refresh-data="refreshEntries()"
          button-mode
          include-add
          include-help
          include-refresh
          title="Clients"
        />
        <v-card-text class="mt-n4">
          <p :class="`${DEFAULT_TEXT}`">A listing of all clients.</p>
        </v-card-text>

        <HelpText :page="CLIENTS_VUE" />

        <v-data-table
          id="client-table"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
          :headers="headers"
          :height="500"
          :items-per-page="25"
          :items="clients"
          :loading="loading"
          :search="search"
          @click:row="viewClient"
          class="mx-4"
          fixed-header
          loading-text="Loading, please wait..."
          no-data-text="Table contains no data."
          no-results-text="No results found."
          sort-by="id"
        >
          <template v-slot:top>
            <v-row dense>
              <v-col class="offset-sm-2 col-sm-8 offset-md-3 col-md-6 mb-4" cols="12">
                <v-text-field
                  v-model="search"
                  :prepend-icon="SEARCH_ICON"
                  label="Search"
                  single-line
                  clearable
                  hide-details
                />
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card>

      <dialog-crud
        :dialog.sync="dialog.show"
        @save-data="createCompleteClient(dialog.payload)"
        :config="dialog.config"
        :payload="dialog.payload"
        :saving="dialog.saving"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep, flatten } from 'lodash'

import Client, { setSelectValidData, TYPE, PROPERTY, } from '../models/dto/Client'
import { ICONS, VUES, ROUTES, TEXT_COLOR, VALID_TABLES, } from '../constants'
import { now, transformError } from '../services/utility.service'
import { push } from '../router'

export default {
  components: {
    CardToolbar: () => import('../components/layout/toolbars/CardToolbar.vue'),
    DialogCrud: () => import('../components/layout/dialog/DialogCrud.vue'),
    HelpText: () => import('../components/layout/HelpText.vue'),
  },
  data: () => ({
    client: new Client(),
    clients: [],
    headers: [
      { text: 'ID', align: 'right', value: 'client_id', },
      { text: 'First Name', align: 'left', value: 'first_name' },
      { text: 'Surname', align: 'left', value: 'surname' },
      { text: 'Loan Code', align: 'right', value: 'loan_code' },
      { text: 'Co-Applicant', align: 'left', value: 'is_co_applicant' },
    ],
    loading: false,
    search: null,
    
    // Dialog window state.
    dialog: { show: false, config: {}, payload: {}, saving: false, },
  }),
  computed: {
    ...mapGetters({
      getValidData: 'valid/getData',
      user: 'user/getUser',
    }),
    /**
     * Metadata required by API calls.
     */
    apiCallMetadata () {
      return { operator_id: this.user.id, dlc: now(), }
    },
  },
  methods: {
    ...mapActions({
      createFullClient: 'client/createFullClient',
      fetchClientsWithLoanCode: 'client/fetchClientsWithLoanCode',
      errorMessage: 'message/errorMessage',
      successMessage: 'message/successMessage',
      initValidStore: 'valid/initStore',
    }),
    createCompleteClient (data) {
      const payload = {
        [TYPE.CLIENT]: {
          ...this.apiCallMetadata,
          [PROPERTY.CLIENT_ID]: null,
          [PROPERTY.FIRST_NAME]: data[PROPERTY.FIRST_NAME],
          [PROPERTY.SURNAME]: data[PROPERTY.SURNAME],
          [PROPERTY.DATE_OF_BIRTH]: data[PROPERTY.DATE_OF_BIRTH],
          [PROPERTY.IS_CO_APPLICANT]: data[PROPERTY.IS_CO_APPLICANT],
        },
        [TYPE.CLIENT_ADDRESS]: {
          ...this.apiCallMetadata,
          [PROPERTY.CLIENT_ID]: null,
          [PROPERTY.ADDRESS]: data[PROPERTY.ADDRESS],
          [PROPERTY.CITY]: data[PROPERTY.CITY],
          [PROPERTY.PROVINCE]: data[PROPERTY.PROVINCE],
          [PROPERTY.POSTAL_CODE]: data[PROPERTY.POSTAL_CODE],
          [PROPERTY.VALID_ADDRESS]: data[PROPERTY.VALID_ADDRESS],
          'country': 'CA',
        },
        [TYPE.CLIENT_CONTACT]: {
          ...this.apiCallMetadata,
          [PROPERTY.CLIENT_ID]: null,
          [PROPERTY.EMAIL_ADDRESS]: data[PROPERTY.EMAIL_ADDRESS],
        },
        [TYPE.CLIENT_PROGRAM_CONTROL]: {
          ...this.apiCallMetadata,
          [PROPERTY.CLIENT_ID]: null,
          [PROPERTY.BOUNCE]: '',
          [PROPERTY.UNSUBSCRIBE]: '',
          [PROPERTY.SUPPRESS_EMAIL]: 'N',
        }
      }

      this.dialog.saving = true

      this.createFullClient( { payload })
        .then(() => {
          this.refreshEntries()
          this.dialog.show = false
          this.successMessage(`Successfully added ${data[PROPERTY.FIRST_NAME]} ${data[PROPERTY.SURNAME]} to the client list.`)
        })
        .catch(error => {
          this.errorMessage(`Failed to add ${data[PROPERTY.FIRST_NAME]} ${data[PROPERTY.SURNAME]} to the client list. ${transformError(error)}`)
        })
        .finally(() => {
          this.dialog.saving = false
        })
    },
    /**
     * Close and reset a dialog window.
     */
    closeDialog () {
      this.dialog = { show: false, config: {}, payload: {}, saving: false, }
    },
    initTemplateConstants () {
      this.CLIENTS_ICON = ICONS.CLIENTS
      this.CLIENTS_VUE = VUES.CLIENTS
      this.DEFAULT_TEXT = TEXT_COLOR.DEFAULT
      this.SEARCH_ICON = ICONS.SEARCH
    },
    /**
     * Initializes and opens the dialog window for this data-type with default values set for the fields.
     */
    openDialog () {
      this.dialog = {
        show: true,
        config: {
          title: 'Create Client',
          fields: {
            fieldGroups: flatten([
              cloneDeep(this.client.getFieldGroups(TYPE.CLIENT)),
              cloneDeep(this.client.getFieldGroups(TYPE.CLIENT_ADDRESS)),
              cloneDeep(this.client.getFieldGroups(TYPE.CLIENT_CONTACT)),
            ]),
            fieldGroupTitles: ['Client', 'Address', 'Contact', ]
          },
        },
        payload: {
          ...cloneDeep(this.client.getDefault(TYPE.CLIENT)),
          ...cloneDeep(this.client.getDefault(TYPE.CLIENT_ADDRESS)),
          ...cloneDeep(this.client.getDefault(TYPE.CLIENT_CONTACT)),
        },
        saving: false,
      }

      delete this.dialog.payload.default;
    },
    refreshEntries (force = true) {
      this.loading = true

      this.fetchClientsWithLoanCode({ force })
        .then(clients => {
          this.clients = clients
        })
        .catch(error => {
          this.errorMessage(`Failed to load client list. ${transformError(error)}`)
        })
        .finally(() => {
          this.loading = false
        })
    },
    viewClient(event) {
      push(`${ROUTES.CLIENTS}/${event.client_id}`)
    },
  },
  async created () {
    this.initTemplateConstants()

    try {
      await this.initValidStore()
      setSelectValidData({ provinces: this.getValidData(VALID_TABLES.PROVINCE), })
    }
    catch(error) {
      this.errorMessage(`Error initializing data stores. If error persists, contact the site administrator.`)
    }
  },
  mounted () {
    this.refreshEntries()
  }
}
</script>

<style scoped>
button {
  outline: none;
}
#client-table {
  cursor: pointer;
}
</style>
